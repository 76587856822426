const Quaternion = function Quaternion (x, y, z, w) {
  this.x = x || 0;
  this.y = y || 0;
  this.z = z || 0;
  this.w = (w !== undefined) ? w : 1;
};

Quaternion.prototype.setFromAxisAngle = function (axis, angle) {
  let halfAngle = angle / 2,
    s = Math.sin(halfAngle);

  this.x = axis.x * s;
  this.y = axis.y * s;
  this.z = axis.z * s;
  this.w = Math.cos(halfAngle);

  return this;
};

Quaternion.prototype.multiplyQuaternions = function (a, b) {
  let qax = a.x,
    qay = a.y,
    qaz = a.z,
    qaw = a.w;
  let qbx = b.x,
    qby = b.y,
    qbz = b.z,
    qbw = b.w;

  this.x = qax * qbw + qaw * qbx + qay * qbz - qaz * qby;
  this.y = qay * qbw + qaw * qby + qaz * qbx - qax * qbz;
  this.z = qaz * qbw + qaw * qbz + qax * qby - qay * qbx;
  this.w = qaw * qbw - qax * qbx - qay * qby - qaz * qbz;

  return this;
};

Quaternion.prototype.setFromRotationMatrix = function (m) {
  let te = m.elements,

    m11 = te[0],
    m12 = te[4],
    m13 = te[8],
    m21 = te[1],
    m22 = te[5],
    m23 = te[9],
    m31 = te[2],
    m32 = te[6],
    m33 = te[10],

    trace = m11 + m22 + m33,
    s;

  if (trace > 0) {

    s = 0.5 / Math.sqrt(trace + 1.0);

    this.w = 0.25 / s;
    this.x = (m32 - m23) * s;
    this.y = (m13 - m31) * s;
    this.z = (m21 - m12) * s;

  } else if (m11 > m22 && m11 > m33) {

    s = 2.0 * Math.sqrt(1.0 + m11 - m22 - m33);

    this.w = (m32 - m23) / s;
    this.x = 0.25 * s;
    this.y = (m12 + m21) / s;
    this.z = (m13 + m31) / s;

  } else if (m22 > m33) {

    s = 2.0 * Math.sqrt(1.0 + m22 - m11 - m33);

    this.w = (m13 - m31) / s;
    this.x = (m12 + m21) / s;
    this.y = 0.25 * s;
    this.z = (m23 + m32) / s;

  } else {

    s = 2.0 * Math.sqrt(1.0 + m33 - m11 - m22);

    this.w = (m21 - m12) / s;
    this.x = (m13 + m31) / s;
    this.y = (m23 + m32) / s;
    this.z = 0.25 * s;

  }

  return this;
};

export default Quaternion;
